import {createRoot} from "react-dom/client";
import Dashboard from "./components/Dashboard";

function init(): void
{
    const salesDashboard = document.querySelector('#sales-dashboard');
    if(salesDashboard !== null){
        const root = createRoot(salesDashboard);
        root.render(<Dashboard/>);
    }
}

document.addEventListener('DOMContentLoaded', function () {
    init();
}, false);